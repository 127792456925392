<template>
  <q-form ref="editForm">
    <c-card title="화기작업 상세정보" class="cardClassDetailForm no-margin">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <c-text
            :required="true"
            :disabled="!isWriting"
            :editable="editable"
            label="작업개요"
            name="workSummary"
            v-model="workPermit.workSummary">
          </c-text>
        </div>
        <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
          <c-process
            :disabled="!isWriting"
            :editable="editable"
            label="단위공정"
            name="processCd"
            v-model="workPermit.processCd">
          </c-process>
        </div>
        <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
          <c-text
            :editable="editable"
            :disabled="!isSopEdit || !isWriting"
            :afterIcon="editable&&isWriting ? [
              { name: 'mode_edit', click: true, callbackName: 'sopEdit', color: '' },
              { name: 'search', click: true, callbackName: 'searchSop', color: 'teal' },
              { name: 'close', click: true, callbackName: 'removeSop', color: 'red' }
            ] : null"
            label="작업명"
            name="sopName"
            v-model="workPermit.sopName"
            @sopEdit="sopEdit"
            @searchSop="searchSop"
            @removeSop="removeSop">
          </c-text>
        </div>
        <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
          <c-vendor
            :disabled="!isWriting"
            :editable="editable"
            label="작업업체"
            name="vendorCd"
            v-model="workPermit.vendorCd"
            @dataChange="vendorChange" />
        </div>
        <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
          <c-text
            :disabled="!isWriting"
            :editable="editable"
            label="작업장소"
            name="workLocation"
            v-model="workPermit.workLocation">
          </c-text>
        </div>
        <div v-if="!isVendorReqeust" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <c-moc 
            :editable="editable"
            :disabled="!isWriting"
            :isSubmit="saveCall.saveCallData"
            :document.sync="workPermit"
            :documentId="workPermit.sopWorkPermitId"
            documentTitle="workSummary"
            mocRelatedTaskCd="RT00000020"
            label="MOC번호"
            name="sopMocId"
            v-model="workPermit.sopMocId"
          />
        </div>
        <div v-if="!isVendorReqeust" class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
          <c-equip
            :disabled="!isWriting"
            :editable="editable"
            :plantCd="workPermit.plantCd"
            :processCd="workPermit.processCd"
            label="관련 설비"
            name="equipmentCd"
            v-model="workPermit.equipmentCd">
          </c-equip>
        </div>
        <div v-if="!isVendorReqeust" class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
          <c-checkbox
            :disabled="!isWriting"
            :editable="editable"
            :isFlag="true"
            label="PSM 관련 여부"
            name="psmFlag"
            v-model="workPermit.psmFlag"
          />
        </div>
        <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
          <c-text
            :disabled="true"
            :editable="editable"
            label="신청인"
            name="request"
            v-model="request">
          </c-text>
        </div>
        <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
          <c-field
            :required="true"
            :disabled="!isWriting"
            :editable="editable"
            :data="workPermit"
            :plantCd="workPermit.plantCd"
            deptValue="workManagerDeptCd"
            type="dept_user"
            userType="lnf"
            label="담당자"
            beforeText=""
            name="workManagerId"
            v-model="workPermit.workManagerId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-checkbox
            :disabled="!isWriting"
            :editable="editable"
            codeGroupCd="SOP_PROTECTIVE_GEAR_CD"
            :isObject="true"
            valueText="sopProtectiveGearName"
            valueKey="sopProtectiveGearCd"
            label="보호구"
            name="protectiveGears"
            v-model="workPermit.protectiveGears"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-textarea
            :disabled="!isWriting"
            :editable="editable"
            :rows="2"
            label="특별요구사항"
            name="specialRequirements"
            v-model="workPermit.specialRequirements">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <c-construnction
            :disabled="!isWriting"
            :editable="editable"
            name="sopConstructionId"
            v-model="workPermit.sopConstructionId">
          </c-construnction>
        </div>
      </template>
    </c-card>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
        <c-table
          title="안전조치 요구사항"
          style="padding-top: 6px;"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="workPermit.checkResults"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :editable="editable&&isWriting"
          :contentsField="contentsField"
          @table-data-change="tableDataChange"
        >
        </c-table>
      </div>
      
      <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9">
        <div class="row">
          <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <c-card title="화기 상세정보" class="cardClassDetailForm" style="margin-bottom: 1px;">
                  <template slot="card-detail">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <c-field 
                        required
                        :disabled="!isWriting"
                        :editable="editable"
                        :data="workPermit"
                        deptValue="fireSafetyManagerDeptCd"
                        type="dept_user" 
                        beforeText=""
                        label="소방안전관리자" 
                        name="fireSafetyManagerId" 
                        v-model="workPermit.fireSafetyManagerId"
                        @dataChange="dataChange" />
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <c-field 
                        :disabled="!isWriting"
                        :editable="editable"
                        :data="workPermit"
                        deptValue="fireWatchmanDeptCd"
                        type="dept_user" 
                        beforeText=""
                        label="화재감시자" 
                        name="fireWatchmanId" 
                        v-model="workPermit.fireWatchmanId"
                        @dataChange="dataChange" />
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                      <c-checkbox
                        :isArray="false"
                        :disabled="!isWriting"
                        :editable="editable"
                        codeGroupCd="SOP_FIRE_WORK_CLASSIFICATION"
                        valueText="codeName"
                        valueKey="code"
                        label="작업구분"
                        name="fireWorkClassification"
                        v-model="workPermit.fireWorkClassification"
                        @datachange="workClassChange"
                      />
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                      <c-text
                        :disabled="!isWriting || disableEtc"
                        :editable="editable"
                        label="작업구분(기타)"
                        name="fireWorkClassificationEtc"
                        v-model="workPermit.fireWorkClassificationEtc"
                        @datachange="dataChange"
                      />
                    </div>
                  </template>
                </c-card>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <c-table
                  title="화기 체크리스트"
                  :columns="gridChecklist.columns"
                  :gridHeight="gridChecklist.height"
                  :data="fireChecklistResults"
                  :filtering="false"
                  :columnSetting="false"
                  :usePaging="false"
                  :editable="editable&&isWriting"
                >
                  <template v-slot:customArea="{ props, col }">
                    <template v-if="props.row['textFlag']==='N'">
                      <q-checkbox
                        dense
                        color="orange-custom"
                        class="tableCheckBox"
                        :disable="!(editable&&isWriting)"
                        true-value="O"
                        false-value="X"
                        v-model="props.row[col.name]"
                        @input="val => checklistChange(props, col)">
                      </q-checkbox>
                    </template>
                    <template v-else>
                      <c-text-column
                        :editable="editable"
                        :disable="!isWriting"
                        :col="col"
                        :props="props"
                        @datachange="checklistChange(props, col)"
                      />
                    </template>
                  </template>
                </c-table>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div style="height:0">
                  <q-banner v-if="!confiFlag" inline-actions class="text-white bg-red permit-table-info-banner">
                    <template v-slot:avatar>
                      <q-icon name="info_outline" color="white" />
                    </template>
                    밀폐 보충작업을 하지 않는 허가서입니다.
                  </q-banner>
                </div>
                <c-table
                  title="밀폐 체크리스트"
                  :columns="gridChecklist.columns"
                  :gridHeight="gridChecklist.height"
                  :data="confiChecklistResults"
                  :filtering="false"
                  :columnSetting="false"
                  :usePaging="false"
                  :editable="editable&&isWriting"
                >
                  <template v-slot:customArea="{ props, col }">
                    <template v-if="props.row['textFlag']==='N'">
                      <q-checkbox
                        dense
                        color="orange-custom"
                        class="tableCheckBox"
                        :disable="!(editable&&isWriting)"
                        true-value="O"
                        false-value="X"
                        v-model="props.row[col.name]"
                        @input="val => checklistChange(props, col)">
                      </q-checkbox>
                    </template>
                    <template v-else>
                      <c-text-column
                        :editable="editable"
                        :disable="!isWriting"
                        :col="col"
                        :props="props"
                        @datachange="checklistChange(props, col)"
                      />
                    </template>
                  </template>
                </c-table>
              </div>
            </div>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <c-card title="발급 정보" class="cardClassDetailForm no-margin">
                  <template slot="card-detail">
                    <div class="col-12">
                      <c-field
                        :required="true"
                        :disabled="!isWriting"
                        :editable="editable"
                        :data="workPermit"
                        deptValue="issuedDeptCd"
                        type="dept_user"
                        label="발급"
                        beforeText=""
                        name="issuedUserId"
                        v-model="workPermit.issuedUserId" />
                    </div>
                    <div class="col-12">
                      <c-field
                        :required="true"
                        :disabled="!isWriting"
                        :editable="editable"
                        :data="workPermit"
                        deptValue="approvalDeptCd"
                        type="dept_user"
                        label="승인"
                        beforeText=""
                        name="approvalUserId"
                        v-model="workPermit.approvalUserId" />
                    </div>
                  </template>
                </c-card>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <c-card title="협조자 정보" class="cardClassDetailForm no-margin">
                  <template slot="card-detail">
                    <div class="col-12">
                      <c-field
                        :disabled="!isWriting"
                        :editable="editable"
                        :data="workPermit"
                        deptValue="relationCooperation1DeptCd"
                        type="dept_user"
                        label="협조자-1"
                        beforeText=""
                        name="relationCooperation1UserId"
                        v-model="workPermit.relationCooperation1UserId" />
                    </div>
                    <div class="col-12">
                      <c-field
                        :disabled="!isWriting"
                        :editable="editable"
                        :data="workPermit"
                        deptValue="relationCooperation2DeptCd"
                        type="dept_user"
                        label="협조자-2"
                        beforeText=""
                        name="relationCooperation2UserId"
                        v-model="workPermit.relationCooperation2UserId" />
                    </div>
                  </template>
                </c-card>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <c-card title="안전조치확인 정보" class="cardClassDetailForm no-margin">
                  <template slot="card-detail">
                    <div class="col-12">
                      <c-field
                        :disabled="!isWriting"
                        :editable="editable"
                        :data="workPermit"
                        deptValue="maintenanceDeptCheckDeptCd"
                        type="dept_user"
                        label="책임자"
                        name="maintenanceDeptCheckUserId"
                        v-model="workPermit.maintenanceDeptCheckUserId" />
                    </div>
                    <div class="col-12">
                      <c-field
                        :disabled="!isWriting"
                        :editable="editable"
                        :data="workPermit"
                        deptValue="maintenanceDeptEntryDeptCd"
                        type="dept_user"
                        label="입회자"
                        name="maintenanceDeptEntryUserId"
                        v-model="workPermit.maintenanceDeptEntryUserId" />
                    </div>
                  </template>
                </c-card>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <c-table
          ref="gasTable"
          title="가스농도 측정"
          :columns="grid2.columns"
          :gridHeight="grid2.height"
          :data="workPermit.gases"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :editable="editable&&editableGas"
          selection="multiple"
          rowKey="sopGasCheckId"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable&&editableGas" label="추가" icon="add" :showLoading="false" @btnClicked="addGas" />
              <c-btn v-if="editable&&editableGas" label="제외" icon="remove" :showLoading="false" @btnClicked="removeGas" />
              <c-btn v-if="editable && isOld && workPermit.swpStepCd !== 'SS00000001' && editableGas && workPermit.gases.length>0" 
                :url="saveGasUrl"
                :param="workPermit"
                :isSubmit="isSaveGas"
                mappingType="PUT"
                icon="save" 
                label="저장" 
                @beforeAction="saveSwpGas"
                @btnCallback="saveSwpGasCallback" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar'
import eventBus from '@/js/eventBus';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'swp-fire',
  props: {
    workPermit: {
      type: Object,
      default: function() {
        return {
          sopWorkPermitId: '',  // 작업허가서 일련번호
          plantCd: '',  // 사업장 코드
          sopName: '',  // 작업명
          mdmSopId: '',  // 안전작업 표준 일련번호_SOP에서 불러와서 관련 정보 표시후 수정
          permitNo: '',  // 허가번호_(YYYYMMDD-01)
          swpStepCd: '',  // 진행단계 코드
          applicationDeptCd: '',  // 신청인 부서코드
          applicationDeptName: '',
          applicationUserId: '',  // 신청인 ID
          applicationUserName: '',
          permitTypeCd: '',  // 허가서 구분_화기/일반
          permitDate: '',  // 허가일
          workStartTime: '', // 작업시작시간
          workEndTime: '', // 작업종료시간
          workTime: [], // 작업시간
          processCd: '',  // 작업공정
          workLocation: '',  // 작업장소
          equipmentCd: '',  // 설비번호_설비마스터
          workSummary: '',  // 작업개요
          specialRequirements: '',  // 특별요구사항
          maintenanceDeptCheckUserId: '',  // 정비부서 책임자 - 안전조치확인
          maintenanceDeptEntryUserId: '',  // 정비부서 입회자 - 안전조치확인
          workCompleteTime: '',  // 작업완료시간
          workCompleteEntryUserId: '',  // 작업완료 입회자/확인자
          workCompleteWorkUserId: '',  // 작업완료 작업자
          issuedDeptCd: '',  // 발급자 부서코드
          issuedUserId: '',  // 발급자 ID
          approvalDeptCd: '',  // 승인자 부서코드
          approvalUserId: '',  // 승인자 ID
          relationCooperation1DeptCd: '',  // 관련부서 협조자1 부서코드
          relationCooperation1UserId: '',  // 관련부서 협조자1 ID
          relationCooperation2DeptCd: '',  // 관련부서 협조자2 부서코드
          relationCooperation2UserId: '',  // 관련부서 협조자2 ID
          vendorCd: '',  // 작업업체 코드
          overWorkFlag: '',  // 연장작업 여부
          vendorFlag: 0, // 업체작성여부
          psmFlag: 'N',  // PSM 관련 여부
          sopMocId: '',  // MOC 일련번호
          assessWriteUserId: '',  // 작업위험성평가_작성자 ID
          assessApprovalUserId: '',  // 작업위험성평가_승인자 ID
          sopConstructionId: '',
          fireWatchmanId: '',  // 화재감시자
          fireWorkClassification: '',  // 화기 작업구분
          fireWorkClassificationEtc: '',  // 화기 작업구분
          fireSafetyManagerId: '',  // 소방안전관리자
          regUserId: '',  // 등록자 ID
          chgUserId: '',  // 수정자 ID
          supWorks: [],
          deleteSupWorks: [],
          checkResults: [],
          deleteCheckResults: [],
          gases: [],
          gasChecks: [], // 저장용
          deleteGasChecks: [],
          deleteGasCheckVals: [],
          maps: [],
          deleteMaps: [],
          workers: [],
          deleteWorkers: [],
          assessments: [],
          deleteAssessments: [],
          overs: [],
          deleteOvers: [],
          protectiveGears: [],
          deleteProtectiveGears: [],
          checklistResults: [],
          deleteChecklistResults: [],
        }
      }
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    isWriting: {
      type: Boolean,
      default: false,
    },
    confiFlag: {
      type: Boolean,
      default: false,
    },
    saveCall: {
      type: Object,
      default: function() {
        return {
          saveCallData: '',
        }
      }
    },
  },
  data() {
    return {
      grid: {
        columns: [],
        data: [],
        height: '613px'
      },
      grid2: {
        columns: [],
        height: '390px'
      },
      gridChecklist: {
        columns: [
          {
            name: 'checklistItemName',
            field: 'checklistItemName',
            label: '점검내용',
            align: 'left',
            sortable: true,
          },
          {
            name: 'checklistResult',
            field: 'checklistResult',
            label: '결과',
            align: 'center',
            sortable: false,
            style: 'width:100px',
            type: 'custom',
          },
        ],
        height: '418px'
      },
      useGasItems: [],
      sopEditing: false,
      editable: true,
      listCheckItemUrl: '',
      listGasItemUrl: '',
      listSopUrl: '',
      saveGasUrl: '',
      isSaveGas: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    request() {
      return this.isVendorReqeust ? this.workPermit.applicationDeptName : this.workPermit.applicationDeptName + ' / ' + this.workPermit.applicationUserName
    },
    isSopEdit() {
      return Boolean(!this.workPermit.mdmSopId && this.workPermit.sopName) || this.sopEditing
    },
    isVendorReqeust() {
      return this.workPermit.vendorFlag > 0
    },
    disableEtc() {
      return this.workPermit.fireWorkClassification ? 
        this.workPermit.fireWorkClassification.indexOf('FWC0000020') === -1 : true
    },
    fireChecklistResults() {
      let data = [];
      this.workPermit.checklistResults
      this.workPermit.checklistResults
      if (this.workPermit.checklistResults 
        && this.workPermit.checklistResults.length > 0) {
          data = this.$_.filter(this.workPermit.checklistResults, { supplementWorkTypeCd: '' })
      }
      return data
    },
    confiChecklistResults() {
      let data = [];
      if (this.confiFlag
        && this.workPermit.checklistResults 
        && this.workPermit.checklistResults.length > 0) {
          data = this.$_.filter(this.workPermit.checklistResults, { supplementWorkTypeCd: 'SSWT000001' })
      }
      return data
    },
    editableGas() {
      return !(this.workPermit.swpStepCd == 'SS00000020' || this.workPermit.swpStepCd == 'SS00000015')
    },
  },
  watch: {
    'workPermit.plantCd'() {
      this.$set(this.workPermit, 'checkResults', [])
      this.$set(this.workPermit, 'gases', [])
      this.getCheckItemList();
      this.getGasItemList();
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    eventBus.$on('vaildForm', (type)=>{
      if (this.$refs['editForm']) {
        this.$refs['editForm'].validate().then(_result => {
          this.$emit('validResult', _result, type);
        });
      }
    });
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listCheckItemUrl = selectConfig.sop.swp.workChekItem.list.url
      this.listGasItemUrl = selectConfig.sop.swp.gas.list.url
      this.listSopUrl = selectConfig.mdm.sop.jobhazard.url;
      this.saveGasUrl = transactionConfig.sop.swp.workPermit.gasUpdate.url
      // code setting
      // list setting
      this.getCheckItemList();
      this.getGasItemList();
    },
    getCheckItemList() {
      /**
       * default 값을 조회
       * 
       * 초기인 경우 workPermit.checkResults에 값을 그대로 적용
       * 작성중 단계인 경우 default 값에 새로운 항목이 있을시에 workPermit.checkResults에 추가하여 입력을 받을 수 있도록 조치
       * 승인완료, 연장, 취소, 작업완료인 경우 저장된 데이터를 그대로 표시하며 이 경우 default값을 조회하지 않도록 조치
       */
      this.grid.columns = [
        {
          name: 'checkItemName',
          field: 'checkItemName',
          label: '항목',
          align: 'left',
          sortable: true,
        },
        {
          name: 'necessaryFlag',
          field: 'necessaryFlag',
          label: '필요',
          align: 'center',
          sortable: false,
          type: 'check',
          'true': 'Y',
          'false': 'N',
          value: 'N',
          setHeader: true,
        },
        {
          name: 'confirmFlag',
          field: 'confirmFlag',
          label: '확인',
          align: 'center',
          sortable: false,
          type: 'check',
          disableTarget: 'necessaryFlag',
          disableCon: 'Y',
          'true': 'Y',
          'false': 'N',
          value: 'N',
          setHeader: true,
        },
      ]
      if (!this.workPermit.plantCd) return;
      if (!this.workPermit.checkResults) this.workPermit.checkResults = [];
      if (!this.isOld || this.workPermit.swpStepCd === 'SS00000001') {
        this.$http.url = this.listCheckItemUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.workPermit.plantCd,
          sopWorkTypeCd: 'SWT0000005',
          useFlag: 'Y',
        }
        this.$http.request((_result) => {
          if (!this.isOld && (!this.workPermit.checkResults || this.workPermit.checkResults.length === 0)) {
            // 최초인 경우 조치항목을 가지고 있지 않음으로 해당 값을 표시
            this.$_.forEach(_result.data, item => {
              this.workPermit.checkResults.push({
                sopWorkPermitId: '',  // 작업허가서 일련번호
                supplementWorkTypeCd: '',  // 보충작업구분
                sopCheckItemId: item.sopCheckItemId,  // 점검항목 일련번호
                checkItemName: item.checkItemName, // 점검항목명
                sopCheckItemKindCd: item.sopCheckItemKindCd, // 체크항목 종류
                necessaryFlag: 'N',  // 필요
                confirmFlag: 'N',  // 확인
                confirmUserId: '',  // 확인자
                confirmUserName: '',  // 확인자명
                regUserId: this.$store.getters.user.userId,  // 등록자 ID
                chgUserId: '',  // 수정자 ID
                editFlag: 'C'
              })
            })
          } else {
            this.$_.forEach(_result.data, item => {
              if (this.$_.findIndex(this.workPermit.checkResults, { sopCheckItemId: item.sopCheckItemId }) === -1) {
                this.workPermit.checkResults.push({
                  sopWorkPermitId: this.workPermit.sopWorkPermitId,  // 작업허가서 일련번호
                  supplementWorkTypeCd: '',  // 보충작업구분
                  sopCheckItemId: item.sopCheckItemId,  // 점검항목 일련번호
                  checkItemName: item.checkItemName, // 점검항목명
                  sopCheckItemKindCd: item.sopCheckItemKindCd, // 체크항목 종류
                  necessaryFlag: 'N',  // 필요
                  confirmFlag: 'N',  // 확인
                  confirmUserId: '',  // 확인자
                  confirmUserName: '',  // 확인자명
                  regUserId: this.$store.getters.user.userId,  // 등록자 ID
                  chgUserId: '',  // 수정자 ID
                  editFlag: 'C'
                })
              }
            })
          }
          
          if (this.$_.findIndex(_result.data, { sopCheckItemKindCd: 'SCIK000005' }) > -1 
            || this.$_.findIndex(this.workPermit.checkResults, { sopCheckItemKindCd: 'SCIK000005' }) > -1) {
            this.grid.columns.push({
              name: 'confirmUserName',
              field: 'confirmUserName',
              label: '확인자',
              align: 'center',
              sortable: true,
              type: 'user',
              userId: 'confirmUserId',
              disableTarget: 'necessaryFlag',
              disableCon: 'Y',
            })
          }
          this.workClassChange();
        },);
      } else {
        if (this.$_.findIndex(this.workPermit.checkResults, { sopCheckItemKindCd: 'SCIK000005' }) > -1) {
          this.grid.columns.push({
            name: 'confirmUserName',
            field: 'confirmUserName',
            label: '확인자',
            align: 'center',
            sortable: true,
            type: 'user',
            userId: 'confirmUserId',
            disableTarget: 'necessaryFlag',
            disableCon: 'Y',
          })
        }
      }
    },
    getGasItemList() {
      let columns = [
        {
          name: 'checkTime',
          field: 'checkTime',
          label: '점검시간',
          align: 'center',
          type: 'datetime',
          minuteStep: 10,
          sortable: false,
          style: 'width:15%',
        },
        {
          name: 'gasCheckMachine',
          field: 'gasCheckMachine',
          label: '점검기기',
          align: 'center',
          sortable: false,
          type: 'text',
          value: '',
          setHeader: true,
          style: 'width:15%',
        },
        {
          name: 'gasCheckUserName',
          field: 'gasCheckUserName',
          label: '점검자',
          align: 'center',
          sortable: false,
          type: 'user',
          userId: 'gasCheckUserId',
          value: '',
          setHeader: true,
          style: 'width:10%',
        },
        {
          name: 'gasConfirmUserName',
          field: 'gasConfirmUserName',
          label: '확인자',
          align: 'center',
          sortable: false,
          type: 'user',
          userId: 'gasConfirmUserId',
          value: '',
          setHeader: true,
          style: 'width:10%',
        },
      ];

      this.$http.url = this.listGasItemUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.workPermit.plantCd,
        // sopWorkTypeCd: 'SWT0000005',
      }
      this.$http.request((_result) => {
        this.useGasItems = [];
        if (this.workPermit.gases && this.workPermit.gases.length > 0) {
          let pushData = [];
          this.$_.forEach(this.workPermit.gases, gas => {
            for (let gasValue in gas) {
              let idx = this.$_.findIndex(_result.data, { sopGasId: gasValue });
              let idx2 = this.$_.findIndex(pushData, { sopGasId: gasValue });
              /**
               * idx의 경우 기본적으로 존재하는지 여부를 판단하지만
               *            예전 입력된 값 중 useFlag N처리된 값이 있는 경우는 허용
               */
              if ((idx > -1 && gas[gasValue] !== '-') && idx2 === -1) {
                this.$set(_result.data[idx], 'me')
                pushData.push(_result.data[idx])
              }
            }
          })
          if (pushData && pushData.length > 0) {
            this.$_.forEach(pushData, item => {
              this.useGasItems.push(item)
              columns.splice(1, 0, {
                // 가스 헤더 적용 로직 추가 필요
                name: item.sopGasId + '_p',
                field: item.sopGasId + '_p',
                label: item.gasName,
                align: 'center',
                sortable: false,
                child: [
                  {
                    name: item.sopGasId,
                    field: item.sopGasId,
                    label: item.safetyStandards,
                    align: 'center',
                    type: 'text',
                    sortable: false,
                    style: 'width:' + (50 / pushData.length) + '%',
                  },
                ]
              })
            })
          }
        } else {
          let useYList = this.$_.filter(_result.data, item => {
            return item.useFlag === 'Y' && this.$_.findIndex(item.workTypes, { sopWorkTypeCd: 'SWT0000005' }) > -1
          });
          this.$_.forEach(useYList, gas => {
            this.useGasItems.push(gas)
            columns.splice(1, 0, {
              // 가스 헤더 적용 로직 추가 필요
              name: gas.sopGasId + '_p',
              field: gas.sopGasId + '_p',
              label: gas.gasName,
              align: 'center',
              sortable: false,
              child: [
                {
                  name: gas.sopGasId,
                  field: gas.sopGasId,
                  label: gas.safetyStandards,
                  align: 'center',
                  type: 'text',
                  sortable: false,
                  style: 'width:' + (50 / useYList.length) + '%',
                },
              ]
            })
          })
        }

        this.grid2.columns = columns
      },);
    },
    addGas() {
      let pushData = {
        sopGasCheckId: uid(),  // 가스농도측정번호
        sopWorkPermitId: this.workPermit.sopWorkPermitId, // 작업허가서 일련번호
        supplementWorkTypeCd: '',  // 보충작업구분
        checkTime: '',  // 점검시간
        gasCheckMachine: '',  // 점검기기명
        gasCheckUserName: '',
        gasCheckUserId: '',  // 점검자
        gasConfirmUserName: '',
        gasConfirmUserId: '',  // 확인자(입회자)
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        editFlag: 'C',
      }
      this.$_.forEach(this.useGasItems, gas => {
        this.$set(pushData, gas.sopGasId, '0');
      });
      
      this.workPermit.gases.push(pushData);
    },
    removeGas() {
      let selectData = this.$refs['gasTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '제외할 행을 지정하세요.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.workPermit.deleteGasChecks) this.workPermit.deleteGasChecks = [];
        if (!this.workPermit.deleteGasCheckVals) this.workPermit.deleteGasCheckVals = [];
        this.$_.forEach(selectData, item => {
          if (this.$_.findIndex(this.workPermit.deleteGasChecks, { sopGasCheckId: item.sopGasCheckId }) === -1
            && item.editFlag !== 'C') {
            this.workPermit.deleteGasChecks.push(item);
            this.$_.forEach(this.useGasItems, gas => {
              this.workPermit.deleteGasCheckVals.push({
                sopGasCheckId: item.sopGasCheckId,
                sopGasId: gas.sopGasId
              })
            })
          }
          this.workPermit.gases = this.$_.reject(this.workPermit.gases, item);
        });
        this.$refs['gasTable'].$refs['compo-table'].clearSelection();
      }
    },
    vendorChange(vendorCd, beforeVendorCd) {
      if (this.workPermit.workers && this.workPermit.workers.length > 0) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '해당 작업업체별로 지정된 작업자 정보들이 제외됩니다. 진행하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            if (!this.workPermit.deleteWorkers) {
              this.workPermit.deleteWorkers = [];
            }
            this.$_.forEach(this.$_.filter(this.workPermit.workers, { vendorCd: beforeVendorCd }), item => {
              if (this.$_.findIndex(this.workPermit.deleteWorkers, { sopWorkerId: item.sopWorkerId }) === -1) {
                this.workPermit.deleteWorkers.push(item)
              }
            })
            this.workPermit.workers = this.$_.reject(this.workPermit.workers, { vendorCd: beforeVendorCd })
          },
          // 취소 callback 함수
          cancelCallback: () => {
            this.$set(this.workPermit, 'vendorCd', beforeVendorCd)
          },
        });
      }
    },
    sopEdit() {
      if (this.workPermit.assessments && this.workPermit.assessments.length > 0) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '작업을 수기로 작성 시 작업위험성평가에 작성된 안전조치사항이 초기화 됩니다. 진행하시겠습니까?',
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.workPermit.assessments, item => {
              if (item.editFlag !== 'C'
                && this.$_.findIndex(this.workPermit.deleteAssessments, { sopWorkSopAssessmentId: item.sopWorkSopAssessmentId }) === -1) {
                this.workPermit.deleteAssessments.push(item)
              }
            });
            this.workPermit.assessments = [];
            
            this.sopEditing = true
            this.workPermit.mdmSopId = null;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        this.sopEditing = true
        this.workPermit.mdmSopId = null;
      }
    },
    searchSop() {
      if (this.workPermit.processCd) {
        if (this.workPermit.assessments && this.workPermit.assessments.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '작업 변경 시 작업위험성평가에 작성된 안전조치사항이 초기화 됩니다. 진행하시겠습니까?',
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.openSopPopup();
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          this.openSopPopup();
        }
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '공정을 지정하신 후 이용바랍니다.',
          type: 'warning', // success / info / warning / error
        });
      }
    },
    openSopPopup() {
      this.popupOptions.title = 'SOP 검색';
      this.popupOptions.param = {
        type: 'single',
        searchType: '1',
        subProcessCd: this.workPermit.processCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/common/process/sopPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeSopPopup;
    },
    closeSopPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (this.workPermit.assessments && this.workPermit.assessments.length > 0) {
          if (!this.workPermit.deleteAssessments) {
            this.workPermit.deleteAssessments = [];
          }
          this.$_.forEach(this.workPermit.assessments, item => {
            if (item.editFlag !== 'C'
              && this.$_.findIndex(this.workPermit.deleteAssessments, { sopWorkSopAssessmentId: item.sopWorkSopAssessmentId }) === -1) {
              this.workPermit.deleteAssessments.push(item)
            }
          });
        }
        if (this.workPermit.mdmSopId !== data[0].mdmSopId) {
          this.$http.url = this.listSopUrl;
          this.$http.type = 'GET';
          this.$http.param = {
            mdmSopId: data[0].mdmSopId,
          }
          this.$http.request((_result) => {
            this.$_.forEach(_result.data, item => {
              item.sopWorkSopAssessmentId = uid();  // 작업허가서_작업위험성평가_일련번호
              item.sopWorkPermitId = this.workPermit.sopWorkPermitId;  // 작업허가서 일련번호
              // item.jobStepName = item.jobStepName;  // 작업단계 명
              // item.riskHazardName = item.riskHazardName;  // 유해위험요인명
              item.safetyActionMeasures = '';  // 안전조치사항
              item.actionEducationFlag = 'N';  // 조치/교육완료여부
              item.beforeFrequency = null,  // 개선전 빈도
              item.beforeStrength = null,  // 개선전 강도
              item.beforeRisk = null,  // 개선전 위험도
              item.afterFrequency = null,  // 개선후 빈도
              item.afterStrength = null,  // 개선후 강도
              item.afterRisk = null,  // 개선후 위험도
              item.regUserId = this.$store.getters.user.userId;  // 등록자 ID
              item.chgUserId = '';  // 수정자 ID
              item.editFlag = 'C'
            })
            this.workPermit.assessments = _result.data;
          },);
          this.sopEditing = false
          this.workPermit.mdmSopId = data[0].mdmSopId
          this.workPermit.sopName = data[0].sopName
        }
      }
    },
    removeSop() {
      if (this.workPermit.assessments && this.workPermit.assessments.length > 0) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '작업 변경 시 작업위험성평가에 작성된 안전조치사항이 초기화 됩니다. 진행하시겠습니까?',
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.workPermit.assessments, item => {
              if (item.editFlag !== 'C'
                && this.$_.findIndex(this.workPermit.deleteAssessments, { sopWorkSopAssessmentId: item.sopWorkSopAssessmentId }) === -1) {
                this.workPermit.deleteAssessments.push(item)
              }
            });
            this.workPermit.assessments = [];
            
            this.sopEditing = false
            this.workPermit.mdmSopId = null
            this.workPermit.sopName = null
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        this.sopEditing = false
        this.workPermit.mdmSopId = null
        this.workPermit.sopName = null
      }
    },
    contentsField(props, col) {
      /**
       * 안전조치사항 항목에서 항목 입력 타입이 '테이블_점검자' 인 경우 테이블에 표시
       * 단, 예전에 '테이블_점검자'였으며 그럼으로 인해 데이터가 저장이 된적이 있는 경우 컴포넌트 표시
       */
      let returnVal = true;
      if (col.name === 'confirmUserName') {
        returnVal = props.row['sopCheckItemKindCd'] === 'SCIK000005' || Boolean(props.row['confirmUserName'])
      }
      return returnVal
    },
    tableDataChange(props, col) {
      if (col.name === 'necessaryFlag' && props.row[col.name] !== 'Y') {
        this.$set(props.row, 'confirmFlag', 'N');
      }
    },
    workClassChange() {
      if (this.disableEtc) {
        this.$set(this.workPermit, 'fireWorkClassificationEtc', '')
      }
      let requiredDatas = ['SCI0000335', 'SCI0000336'];
      if (this.workPermit.fireWorkClassification
        && (this.workPermit.fireWorkClassification.indexOf('FWC0000001') > -1
          || this.workPermit.fireWorkClassification.indexOf('FWC0000005') > -1
          || this.workPermit.fireWorkClassification.indexOf('FWC0000010') > -1
          || this.workPermit.fireWorkClassification.indexOf('FWC0000015') > -1)
      ) {
        // 용접, 용단, 땜, 연마 클릭 시 
        // 화재감시자 배치 및 소화기 배치 필수값설정
        // ** 사업장이 대구공장 밖에 없음으로 다음과 같이 셋팅
        // SCI0000335, SCI0000336
        this.$_.forEach(this.workPermit.checkResults, checkResult => {
          if (requiredDatas.indexOf(checkResult.sopCheckItemId) > -1) {
            this.$set(checkResult, 'necessaryFlag', 'Y')
            this.$set(checkResult, 'necessaryDisabledFlag', 'Y')
            if (checkResult['editFlag'] !== 'C') {
              checkResult['editFlag'] = 'U'
              checkResult['chgUserId'] = this.$store.getters.user.userId
            }
          }
        })
      } else {
        this.$_.forEach(this.workPermit.checkResults, checkResult => {
          if (requiredDatas.indexOf(checkResult.sopCheckItemId) > -1) {
            this.$set(checkResult, 'necessaryFlag', 'N')
            this.$set(checkResult, 'necessaryDisabledFlag', null)
            if (checkResult['editFlag'] !== 'C') {
              checkResult['editFlag'] = 'U'
              checkResult['chgUserId'] = this.$store.getters.user.userId
            }
          }
        })
      }
      this.dataChange();
    },
    dataChange() {
      if (this.workPermit.editFlag !== 'C') {
        this.workPermit.editFlag = 'U'
        this.workPermit.chgUserId = this.$store.getters.user.userId
      }
    },
    checklistChange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    saveSwpGas() {
      this.$emit('saveWorkPermit')
    },
    saveSwpGasCallback(result) {
      this.workPermit.sopWorkPermitId = result.data
      this.$emit('saveWorkPermitCallback')
    },
  }
};
</script>
<style lang="sass">
.gas-item
  padding-top:18px !important
  .q-field__inner
    padding-bottom: 0px !important
  .customText
    padding-bottom: 0px !important
.gas-card-title
  font-size: 15px
  font-weight: 800
</style>
<style scoped>
.permit-table-info-banner {
  position: relative;
  top: 150px;
  left: 50%;
  transform: translate(-50%);
  width: 80%;
  z-index: 1000;
}
</style>