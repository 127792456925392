var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm no-margin",
          attrs: { title: "화기작업 상세정보" },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
              [
                _c("c-text", {
                  attrs: {
                    required: true,
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    label: "작업개요",
                    name: "workSummary",
                  },
                  model: {
                    value: _vm.workPermit.workSummary,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "workSummary", $$v)
                    },
                    expression: "workPermit.workSummary",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
              [
                _c("c-process", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    label: "단위공정",
                    name: "processCd",
                  },
                  model: {
                    value: _vm.workPermit.processCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "processCd", $$v)
                    },
                    expression: "workPermit.processCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: !_vm.isSopEdit || !_vm.isWriting,
                    afterIcon:
                      _vm.editable && _vm.isWriting
                        ? [
                            {
                              name: "mode_edit",
                              click: true,
                              callbackName: "sopEdit",
                              color: "",
                            },
                            {
                              name: "search",
                              click: true,
                              callbackName: "searchSop",
                              color: "teal",
                            },
                            {
                              name: "close",
                              click: true,
                              callbackName: "removeSop",
                              color: "red",
                            },
                          ]
                        : null,
                    label: "작업명",
                    name: "sopName",
                  },
                  on: {
                    sopEdit: _vm.sopEdit,
                    searchSop: _vm.searchSop,
                    removeSop: _vm.removeSop,
                  },
                  model: {
                    value: _vm.workPermit.sopName,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "sopName", $$v)
                    },
                    expression: "workPermit.sopName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
              [
                _c("c-vendor", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    label: "작업업체",
                    name: "vendorCd",
                  },
                  on: { dataChange: _vm.vendorChange },
                  model: {
                    value: _vm.workPermit.vendorCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "vendorCd", $$v)
                    },
                    expression: "workPermit.vendorCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
              [
                _c("c-text", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    label: "작업장소",
                    name: "workLocation",
                  },
                  model: {
                    value: _vm.workPermit.workLocation,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "workLocation", $$v)
                    },
                    expression: "workPermit.workLocation",
                  },
                }),
              ],
              1
            ),
            !_vm.isVendorReqeust
              ? _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                  [
                    _c("c-moc", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: !_vm.isWriting,
                        isSubmit: _vm.saveCall.saveCallData,
                        document: _vm.workPermit,
                        documentId: _vm.workPermit.sopWorkPermitId,
                        documentTitle: "workSummary",
                        mocRelatedTaskCd: "RT00000020",
                        label: "MOC번호",
                        name: "sopMocId",
                      },
                      on: {
                        "update:document": function ($event) {
                          _vm.workPermit = $event
                        },
                      },
                      model: {
                        value: _vm.workPermit.sopMocId,
                        callback: function ($$v) {
                          _vm.$set(_vm.workPermit, "sopMocId", $$v)
                        },
                        expression: "workPermit.sopMocId",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isVendorReqeust
              ? _c(
                  "div",
                  { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
                  [
                    _c("c-equip", {
                      attrs: {
                        disabled: !_vm.isWriting,
                        editable: _vm.editable,
                        plantCd: _vm.workPermit.plantCd,
                        processCd: _vm.workPermit.processCd,
                        label: "관련 설비",
                        name: "equipmentCd",
                      },
                      model: {
                        value: _vm.workPermit.equipmentCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.workPermit, "equipmentCd", $$v)
                        },
                        expression: "workPermit.equipmentCd",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isVendorReqeust
              ? _c(
                  "div",
                  { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
                  [
                    _c("c-checkbox", {
                      attrs: {
                        disabled: !_vm.isWriting,
                        editable: _vm.editable,
                        isFlag: true,
                        label: "PSM 관련 여부",
                        name: "psmFlag",
                      },
                      model: {
                        value: _vm.workPermit.psmFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.workPermit, "psmFlag", $$v)
                        },
                        expression: "workPermit.psmFlag",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
              [
                _c("c-text", {
                  attrs: {
                    disabled: true,
                    editable: _vm.editable,
                    label: "신청인",
                    name: "request",
                  },
                  model: {
                    value: _vm.request,
                    callback: function ($$v) {
                      _vm.request = $$v
                    },
                    expression: "request",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
              [
                _c("c-field", {
                  attrs: {
                    required: true,
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    data: _vm.workPermit,
                    plantCd: _vm.workPermit.plantCd,
                    deptValue: "workManagerDeptCd",
                    type: "dept_user",
                    userType: "lnf",
                    label: "담당자",
                    beforeText: "",
                    name: "workManagerId",
                  },
                  model: {
                    value: _vm.workPermit.workManagerId,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "workManagerId", $$v)
                    },
                    expression: "workPermit.workManagerId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
              [
                _c("c-checkbox", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    codeGroupCd: "SOP_PROTECTIVE_GEAR_CD",
                    isObject: true,
                    valueText: "sopProtectiveGearName",
                    valueKey: "sopProtectiveGearCd",
                    label: "보호구",
                    name: "protectiveGears",
                  },
                  model: {
                    value: _vm.workPermit.protectiveGears,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "protectiveGears", $$v)
                    },
                    expression: "workPermit.protectiveGears",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    rows: 2,
                    label: "특별요구사항",
                    name: "specialRequirements",
                  },
                  model: {
                    value: _vm.workPermit.specialRequirements,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "specialRequirements", $$v)
                    },
                    expression: "workPermit.specialRequirements",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
              [
                _c("c-construnction", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    name: "sopConstructionId",
                  },
                  model: {
                    value: _vm.workPermit.sopConstructionId,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "sopConstructionId", $$v)
                    },
                    expression: "workPermit.sopConstructionId",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3" },
          [
            _c("c-table", {
              staticStyle: { "padding-top": "6px" },
              attrs: {
                title: "안전조치 요구사항",
                columns: _vm.grid.columns,
                gridHeight: _vm.grid.height,
                data: _vm.workPermit.checkResults,
                filtering: false,
                columnSetting: false,
                usePaging: false,
                editable: _vm.editable && _vm.isWriting,
                contentsField: _vm.contentsField,
              },
              on: { "table-data-change": _vm.tableDataChange },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-9 col-lg-9" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-xs-8 col-sm-8 col-md-8 col-lg-8" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" },
                  [
                    _c(
                      "c-card",
                      {
                        staticClass: "cardClassDetailForm",
                        staticStyle: { "margin-bottom": "1px" },
                        attrs: { title: "화기 상세정보" },
                      },
                      [
                        _c("template", { slot: "card-detail" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-6 col-lg-6",
                            },
                            [
                              _c("c-field", {
                                attrs: {
                                  required: "",
                                  disabled: !_vm.isWriting,
                                  editable: _vm.editable,
                                  data: _vm.workPermit,
                                  deptValue: "fireSafetyManagerDeptCd",
                                  type: "dept_user",
                                  beforeText: "",
                                  label: "소방안전관리자",
                                  name: "fireSafetyManagerId",
                                },
                                on: { dataChange: _vm.dataChange },
                                model: {
                                  value: _vm.workPermit.fireSafetyManagerId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workPermit,
                                      "fireSafetyManagerId",
                                      $$v
                                    )
                                  },
                                  expression: "workPermit.fireSafetyManagerId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-6 col-lg-6",
                            },
                            [
                              _c("c-field", {
                                attrs: {
                                  disabled: !_vm.isWriting,
                                  editable: _vm.editable,
                                  data: _vm.workPermit,
                                  deptValue: "fireWatchmanDeptCd",
                                  type: "dept_user",
                                  beforeText: "",
                                  label: "화재감시자",
                                  name: "fireWatchmanId",
                                },
                                on: { dataChange: _vm.dataChange },
                                model: {
                                  value: _vm.workPermit.fireWatchmanId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workPermit,
                                      "fireWatchmanId",
                                      $$v
                                    )
                                  },
                                  expression: "workPermit.fireWatchmanId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-8 col-lg-8",
                            },
                            [
                              _c("c-checkbox", {
                                attrs: {
                                  isArray: false,
                                  disabled: !_vm.isWriting,
                                  editable: _vm.editable,
                                  codeGroupCd: "SOP_FIRE_WORK_CLASSIFICATION",
                                  valueText: "codeName",
                                  valueKey: "code",
                                  label: "작업구분",
                                  name: "fireWorkClassification",
                                },
                                on: { datachange: _vm.workClassChange },
                                model: {
                                  value: _vm.workPermit.fireWorkClassification,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workPermit,
                                      "fireWorkClassification",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "workPermit.fireWorkClassification",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-4 col-lg-4",
                            },
                            [
                              _c("c-text", {
                                attrs: {
                                  disabled: !_vm.isWriting || _vm.disableEtc,
                                  editable: _vm.editable,
                                  label: "작업구분(기타)",
                                  name: "fireWorkClassificationEtc",
                                },
                                on: { datachange: _vm.dataChange },
                                model: {
                                  value:
                                    _vm.workPermit.fireWorkClassificationEtc,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workPermit,
                                      "fireWorkClassificationEtc",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "workPermit.fireWorkClassificationEtc",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-6" },
                  [
                    _c("c-table", {
                      attrs: {
                        title: "화기 체크리스트",
                        columns: _vm.gridChecklist.columns,
                        gridHeight: _vm.gridChecklist.height,
                        data: _vm.fireChecklistResults,
                        filtering: false,
                        columnSetting: false,
                        usePaging: false,
                        editable: _vm.editable && _vm.isWriting,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "customArea",
                          fn: function ({ props, col }) {
                            return [
                              props.row["textFlag"] === "N"
                                ? [
                                    _c("q-checkbox", {
                                      staticClass: "tableCheckBox",
                                      attrs: {
                                        dense: "",
                                        color: "orange-custom",
                                        disable: !(
                                          _vm.editable && _vm.isWriting
                                        ),
                                        "true-value": "O",
                                        "false-value": "X",
                                      },
                                      on: {
                                        input: (val) =>
                                          _vm.checklistChange(props, col),
                                      },
                                      model: {
                                        value: props.row[col.name],
                                        callback: function ($$v) {
                                          _vm.$set(props.row, col.name, $$v)
                                        },
                                        expression: "props.row[col.name]",
                                      },
                                    }),
                                  ]
                                : [
                                    _c("c-text-column", {
                                      attrs: {
                                        editable: _vm.editable,
                                        disable: !_vm.isWriting,
                                        col: col,
                                        props: props,
                                      },
                                      on: {
                                        datachange: function ($event) {
                                          return _vm.checklistChange(props, col)
                                        },
                                      },
                                    }),
                                  ],
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-6" },
                  [
                    _c(
                      "div",
                      { staticStyle: { height: "0" } },
                      [
                        !_vm.confiFlag
                          ? _c(
                              "q-banner",
                              {
                                staticClass:
                                  "text-white bg-red permit-table-info-banner",
                                attrs: { "inline-actions": "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "avatar",
                                      fn: function () {
                                        return [
                                          _c("q-icon", {
                                            attrs: {
                                              name: "info_outline",
                                              color: "white",
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  4205562712
                                ),
                              },
                              [
                                _vm._v(
                                  " 밀폐 보충작업을 하지 않는 허가서입니다. "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("c-table", {
                      attrs: {
                        title: "밀폐 체크리스트",
                        columns: _vm.gridChecklist.columns,
                        gridHeight: _vm.gridChecklist.height,
                        data: _vm.confiChecklistResults,
                        filtering: false,
                        columnSetting: false,
                        usePaging: false,
                        editable: _vm.editable && _vm.isWriting,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "customArea",
                          fn: function ({ props, col }) {
                            return [
                              props.row["textFlag"] === "N"
                                ? [
                                    _c("q-checkbox", {
                                      staticClass: "tableCheckBox",
                                      attrs: {
                                        dense: "",
                                        color: "orange-custom",
                                        disable: !(
                                          _vm.editable && _vm.isWriting
                                        ),
                                        "true-value": "O",
                                        "false-value": "X",
                                      },
                                      on: {
                                        input: (val) =>
                                          _vm.checklistChange(props, col),
                                      },
                                      model: {
                                        value: props.row[col.name],
                                        callback: function ($$v) {
                                          _vm.$set(props.row, col.name, $$v)
                                        },
                                        expression: "props.row[col.name]",
                                      },
                                    }),
                                  ]
                                : [
                                    _c("c-text-column", {
                                      attrs: {
                                        editable: _vm.editable,
                                        disable: !_vm.isWriting,
                                        col: col,
                                        props: props,
                                      },
                                      on: {
                                        datachange: function ($event) {
                                          return _vm.checklistChange(props, col)
                                        },
                                      },
                                    }),
                                  ],
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "col-xs-4 col-sm-4 col-md-4 col-lg-4" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" },
                  [
                    _c(
                      "c-card",
                      {
                        staticClass: "cardClassDetailForm no-margin",
                        attrs: { title: "발급 정보" },
                      },
                      [
                        _c("template", { slot: "card-detail" }, [
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("c-field", {
                                attrs: {
                                  required: true,
                                  disabled: !_vm.isWriting,
                                  editable: _vm.editable,
                                  data: _vm.workPermit,
                                  deptValue: "issuedDeptCd",
                                  type: "dept_user",
                                  label: "발급",
                                  beforeText: "",
                                  name: "issuedUserId",
                                },
                                model: {
                                  value: _vm.workPermit.issuedUserId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workPermit,
                                      "issuedUserId",
                                      $$v
                                    )
                                  },
                                  expression: "workPermit.issuedUserId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("c-field", {
                                attrs: {
                                  required: true,
                                  disabled: !_vm.isWriting,
                                  editable: _vm.editable,
                                  data: _vm.workPermit,
                                  deptValue: "approvalDeptCd",
                                  type: "dept_user",
                                  label: "승인",
                                  beforeText: "",
                                  name: "approvalUserId",
                                },
                                model: {
                                  value: _vm.workPermit.approvalUserId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workPermit,
                                      "approvalUserId",
                                      $$v
                                    )
                                  },
                                  expression: "workPermit.approvalUserId",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" },
                  [
                    _c(
                      "c-card",
                      {
                        staticClass: "cardClassDetailForm no-margin",
                        attrs: { title: "협조자 정보" },
                      },
                      [
                        _c("template", { slot: "card-detail" }, [
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("c-field", {
                                attrs: {
                                  disabled: !_vm.isWriting,
                                  editable: _vm.editable,
                                  data: _vm.workPermit,
                                  deptValue: "relationCooperation1DeptCd",
                                  type: "dept_user",
                                  label: "협조자-1",
                                  beforeText: "",
                                  name: "relationCooperation1UserId",
                                },
                                model: {
                                  value:
                                    _vm.workPermit.relationCooperation1UserId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workPermit,
                                      "relationCooperation1UserId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "workPermit.relationCooperation1UserId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("c-field", {
                                attrs: {
                                  disabled: !_vm.isWriting,
                                  editable: _vm.editable,
                                  data: _vm.workPermit,
                                  deptValue: "relationCooperation2DeptCd",
                                  type: "dept_user",
                                  label: "협조자-2",
                                  beforeText: "",
                                  name: "relationCooperation2UserId",
                                },
                                model: {
                                  value:
                                    _vm.workPermit.relationCooperation2UserId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workPermit,
                                      "relationCooperation2UserId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "workPermit.relationCooperation2UserId",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" },
                  [
                    _c(
                      "c-card",
                      {
                        staticClass: "cardClassDetailForm no-margin",
                        attrs: { title: "안전조치확인 정보" },
                      },
                      [
                        _c("template", { slot: "card-detail" }, [
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("c-field", {
                                attrs: {
                                  disabled: !_vm.isWriting,
                                  editable: _vm.editable,
                                  data: _vm.workPermit,
                                  deptValue: "maintenanceDeptCheckDeptCd",
                                  type: "dept_user",
                                  label: "책임자",
                                  name: "maintenanceDeptCheckUserId",
                                },
                                model: {
                                  value:
                                    _vm.workPermit.maintenanceDeptCheckUserId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workPermit,
                                      "maintenanceDeptCheckUserId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "workPermit.maintenanceDeptCheckUserId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("c-field", {
                                attrs: {
                                  disabled: !_vm.isWriting,
                                  editable: _vm.editable,
                                  data: _vm.workPermit,
                                  deptValue: "maintenanceDeptEntryDeptCd",
                                  type: "dept_user",
                                  label: "입회자",
                                  name: "maintenanceDeptEntryUserId",
                                },
                                model: {
                                  value:
                                    _vm.workPermit.maintenanceDeptEntryUserId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workPermit,
                                      "maintenanceDeptEntryUserId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "workPermit.maintenanceDeptEntryUserId",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" },
          [
            _c(
              "c-table",
              {
                ref: "gasTable",
                attrs: {
                  title: "가스농도 측정",
                  columns: _vm.grid2.columns,
                  gridHeight: _vm.grid2.height,
                  data: _vm.workPermit.gases,
                  filtering: false,
                  columnSetting: false,
                  usePaging: false,
                  editable: _vm.editable && _vm.editableGas,
                  selection: "multiple",
                  rowKey: "sopGasCheckId",
                },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable && _vm.editableGas
                          ? _c("c-btn", {
                              attrs: {
                                label: "추가",
                                icon: "add",
                                showLoading: false,
                              },
                              on: { btnClicked: _vm.addGas },
                            })
                          : _vm._e(),
                        _vm.editable && _vm.editableGas
                          ? _c("c-btn", {
                              attrs: {
                                label: "제외",
                                icon: "remove",
                                showLoading: false,
                              },
                              on: { btnClicked: _vm.removeGas },
                            })
                          : _vm._e(),
                        _vm.editable &&
                        _vm.isOld &&
                        _vm.workPermit.swpStepCd !== "SS00000001" &&
                        _vm.editableGas &&
                        _vm.workPermit.gases.length > 0
                          ? _c("c-btn", {
                              attrs: {
                                url: _vm.saveGasUrl,
                                param: _vm.workPermit,
                                isSubmit: _vm.isSaveGas,
                                mappingType: "PUT",
                                icon: "save",
                                label: "저장",
                              },
                              on: {
                                beforeAction: _vm.saveSwpGas,
                                btnCallback: _vm.saveSwpGasCallback,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }